import React, {useCallback, useEffect} from 'react'
import {useRouter} from 'next/router'
import SnackbarLoader from '@component/snackbar/SnackbarLoader'
import {setCookie} from '@util/cookie'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import dynamic from 'next/dynamic'

const BottomSheetArea = dynamic(() => import('@component/core/BottomSheetArea'), {ssr: false})

const Loader = () => {
    const router = useRouter()

    const {refetch: refetchExchanges} = useQueryGetExchanges()

    const setLanguageCookie = useCallback((language: string) => {
        setCookie('language', language)
    }, [])

    useEffect(() => {
        setLanguageCookie(router.locale)

        refetchExchanges()
    }, [router.locale, refetchExchanges, setLanguageCookie])

    return (
        <>
            <SnackbarLoader />
            <BottomSheetArea />
        </>
    )
}

export default Loader
