import React from 'react'
import {IFuncItem, parseTagComponent} from '@component/Text/parseTagComponent'

interface IProps {
    children?: any
    className?: string
    funcList?: IFuncItem[]
    onClick?: () => void
    isHoverStyle?: boolean
}

const TagSpan: React.FC<IProps> = ({className, children, funcList, onClick, isHoverStyle}) => {
    return (
        <span className={`font-sans antialiased ${className}`} suppressHydrationWarning={true} onClick={onClick}>
            {parseTagComponent(children, funcList, isHoverStyle)}
        </span>
    )
}

export default TagSpan
