import React from 'react'
import useText from '@hook/useText'

interface IProps {
    children?: any
    className?: string
    onClick?: React.MouseEventHandler<HTMLParagraphElement>
    enableHyperlink?: boolean
    enablePreWhiteSpace?: boolean
    inlineStyle?: any
}

const Text: React.FC<IProps> = ({
    className,
    onClick,
    enableHyperlink = false,
    enablePreWhiteSpace = true,
    children,
    inlineStyle,
}) => {
    const {renderHyperlink} = useText()

    return (
        <p
            className={`antialiased font-sans ${className} ${
                enablePreWhiteSpace === true ? 'whitespace-pre-wrap' : ''
            }`}
            style={{...inlineStyle}}
            onClick={onClick}
            suppressHydrationWarning={true}>
            {enableHyperlink ? renderHyperlink(children as string) : (children as string)}
        </p>
    )
}

export default Text
